/* for hiding process is not defined bug from console */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}
.chart {
  height: 320px !important;
  width: 320px !important;
  justify-items: center;
  margin: auto;
}

.drawer {
  position: fixed;
  z-index: 9999;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer > * {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open {
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer .drawer-mask {
  background: #000;
  opacity: 0;
  width: 100%;
  height: 0;
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
}
.drawer-content-wrapper {
  position: absolute;
  background: #fff;
}
.drawer-content {
  overflow: auto;
  z-index: 1;
  position: relative;
}
.drawer-handle {
  position: absolute;
  top: 72px;
  width: 41px;
  height: 40px;
  cursor: pointer;
  z-index: 0;
  text-align: center;
  line-height: 40px;
  font-size: 16px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.drawer-handle-icon {
  width: 14px;
  height: 2px;
  background: #333;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before,
.drawer-handle-icon:after {
  content: '';
  display: block;
  position: absolute;
  background: #333;
  width: 100%;
  height: 2px;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer-handle-icon:before {
  top: -5px;
}
.drawer-handle-icon:after {
  top: 5px;
}
.drawer-left,
.drawer-right {
  width: 0%;
  height: 100%;
}
.drawer-left .drawer-content-wrapper,
.drawer-right .drawer-content-wrapper,
.drawer-left .drawer-content,
.drawer-right .drawer-content {
  height: 100%;
}
.drawer-left.drawer-open,
.drawer-right.drawer-open {
  width: 100%;
}
.drawer-left.drawer-open.no-mask,
.drawer-right.drawer-open.no-mask {
  width: 0%;
}
.drawer-left {
  top: 0;
  left: 0;
}
.drawer-left .drawer-handle {
  right: -40px;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 4px 4px 0;
}
.drawer-left.drawer-open .drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right {
  top: 0;
  right: 0;
}
.drawer-right .drawer-content-wrapper {
  right: 0;
}
.drawer-right .drawer-handle {
  left: -40px;
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 0 0 4px;
}
.drawer-right.drawer-open .drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.drawer-right.drawer-open.no-mask {
  right: 1px;
  transform: translateX(1px);
}
.drawer-top,
.drawer-bottom {
  width: 100%;
  height: 0%;
}
.drawer-top .drawer-content-wrapper,
.drawer-bottom .drawer-content-wrapper,
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  width: 100%;
}
.drawer-top .drawer-content,
.drawer-bottom .drawer-content {
  height: 100%;
}
.drawer-top.drawer-open,
.drawer-bottom.drawer-open {
  height: 100%;
}
.drawer-top.drawer-open.no-mask,
.drawer-bottom.drawer-open.no-mask {
  height: 0%;
}
.drawer-top .drawer-handle,
.drawer-bottom .drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.drawer-top {
  top: 0;
  left: 0;
}
.drawer-top .drawer-handle {
  top: auto;
  bottom: -40px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 0 0 4px 4px;
}
.drawer-top.drawer-open .drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom {
  bottom: 0;
  left: 0;
}
.drawer-bottom .drawer-content-wrapper {
  bottom: 0;
}
.drawer-bottom .drawer-handle {
  top: -40px;
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px 4px 0 0;
}
.drawer-bottom.drawer-open .drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.drawer-bottom.drawer-open.no-mask {
  bottom: 1px;
  transform: translateY(1px);
}
.drawer.drawer-open .drawer-mask {
  opacity: 0.3;
  height: 100%;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.drawer.drawer-open .drawer-handle-icon {
  background: transparent;
}
.drawer.drawer-open .drawer-handle-icon:before {
  transform: translateY(5px) rotate(45deg);
}
.drawer.drawer-open .drawer-handle-icon:after {
  transform: translateY(-5px) rotate(-45deg);
}

/* drawer */
.drawer {
  overflow: visible;
  z-index: 9989 !important;
}
.drawer .drawer-content-wrapper {
  width: 50vw;
  transform: translateX(0px);
}
.drawer .drawer-content-wrapper .drawer-handle {
  display: none !important;
}
@media (min-width: 320px) and (max-width: 767px) {
  .drawer .drawer-content-wrapper {
    width: 100vw;
  }
}

/* modal */
.custom-modal header button {
  display: none;
}

/*input tag css */
.react-tag-input {
  width: 100%;
  background: #f4f5f7;
  border: 1px solid #e5e7eb;
  border-radius: 0.375rem;
  padding: 0;
}
.react-tag-input__input {
  font-size: 0.875rem;
  margin: 0;
  height: 3rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  background: transparent;
  color: #4c4f52;
}
.react-tag-input__input:focus {
  background: #ffffff;
}
.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  font-size: 10px;
  font-weight: 400;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}
.react-tag-input__tag {
  margin: 0.175rem 0.375rem;
  color: #24262d;
}
.theme-dark .react-tag-input {
  color: #ebebeb;
  border-color: #4c4f52;
  background-color: #24262d;
}
.theme-dark .react-tag-input__input {
  color: #ebebeb;
}
.theme-dark .react-tag-input__input:focus {
  background-color: #24262d;
  border-color: #707275;
}

/* dropdown style */
/* .dropdown {
  display: none !important;
} */

/* notification box css */

.notification-box {
  width: 20rem;
  height: 22rem;
}
.notification-content h6 {
  font-size: 13px;
  margin-bottom: 5px;
  -webkit-line-clamp: 1;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.notification-content p span {
  font-size: 11px;
}

.confirmation-c {
  position: fixed;
  background: black;
  width: 100vw;
  height: 100vh;
  z-index: 40;
  top: 0px;
  left: 0;
  opacity: 0.5;
}


@media (max-width: 392px) {
  .loader-c {
    left: 132px !important;
  }
}



@media (max-width: 420px) {
  .loader-c {
    left: 143px !important;
    top: 362px !important;
  }
}

@media (max-width: 378px) {
  .loader-c {
    left: 143px !important;
    top: 216px !important;
  }
}


@media (max-width: 282px) {
  .loader-c {
    left: 80px !important;
  }
}

.popup {
  z-index: 50;
  background: white;
  width: 555px;
  padding: 50px;
  border-radius: 15px;
  position: absolute;
  /* transition: 0.9s all; */
  left:50%;
  transform: translate(-50%,15%);
}

.popup h1 {
  font-size: 25px;
  font-weight: 600;
  text-align: center;
  padding: 0 26px;
  color: #495057
}

.popup p {
  font-size: 19px;
  text-align: center;
  /* color: #878a99; */
  padding: 20px;
  line-height: 1.2;
}

@media (max-width: 415px) {
  .popup {
  width: 345px !important;
  padding: 15px !important;
}
}

@media (max-width: 281px) {
  .popup {
    width: 268px !important;
    padding: 0px !important;
  }
}

.table-p {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

#paginate_id {
  background: #0e9f6e;
  padding: 0px 12px;
  border-radius: 3px;
  cursor: pointer;
  color: white;
}

#paginate_id_notselected {
  background: white;
  padding: 0px 12px;
  border-radius: 3px;
  cursor: pointer;
  color: #0e9f6e;
  border: 1px solid #0e9f6e;
}

@media (max-width: 767px) {
  .setWidth {
       width: 100%;
       height: 100%;
  }
  .setMargin{
       margin: 0px;
  }
}

@media (min-width: 768px) {
  .mt-lg {
      margin-top: 33px !important;
  }
}